import Image from 'next/image'
import API from '../../api'
import { FC, useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { InfinityList, useInfinityScroll } from '../../components/InfinityList'
import client from '../../core/api/client'
import { parsePramsToQueryString } from '../../core/utilities/query-string'
// import useAsyncAction from '../../core/utilities/useAsyncAction'
import NoResultImage from './assets/no_result.png'
import JobItem from './component/JobItem'
import JobSearch from './component/JobSearch'
import { JobIndexType, JobSearchValue } from './job.types'
import useLocation from '../../hooks/useLocation'
import { useJobCategories } from '../../hooks/useJobCategory'
import { Trans, useTranslation } from 'react-i18next'
import PublicPageMeta from '../../components/meta/PublicPageMeta'
import dayjs from 'dayjs'
import ENV from '../../env'
import { useRouter } from 'next/router'
import { FastJsonList } from '../../types/RailsResponseType'
import { useSearchQuery } from './hooks/useSearchQuery'

export const PAGE_SIZE_JOB_LIST = 12

const JobList: FC<{ jobList: FastJsonList<JobIndexType, string> }> = ({
  jobList
}) => {
  const { locale } = useRouter()
  const locations = useLocation()
  const { job_categories } = useJobCategories()
  // const { query: searchValue, pushQuery: setSearchValue } = useSearchQuery()
  const [searchValue, setSearchValue] = useState<JobSearchValue>()

  const { t } = useTranslation()

  const paging = useInfinityScroll(
    jobList,
    (page?: number, search?: Object) => {
      const queryString =
        `employment_type[]=9&employment_type[]=6&items=${PAGE_SIZE_JOB_LIST}&page=${page}&` +
        (parsePramsToQueryString({ ...searchValue, ...(search || {}) }) || '')
      return client.get(`${API.REMOTE_JOB_GET_JOB_LIST}?${queryString}`)
    }
  )

  useEffect(() => {
    if (searchValue) paging.reload()
  }, [
    searchValue?.job_category_id,
    searchValue?.location_id,
    searchValue?.title
  ])

  return !jobList ? null : (
    <>
      <PublicPageMeta
        url={`${ENV.PUBLIC_REMOTE_JOB_URL}${locale === 'en' ? '/en' : ''}`}
        title={t('meta:homepage.title', {
          num_job: paging.meta?.count,
          month: dayjs().format('MM/YYYY')
        })}
        description={t('meta:homepage.description', {
          num_job: paging.meta?.count
        })}
        keywords={t('meta:homepage.keyword')}
        // image={`${ENV.PUBLIC_REMOTE_JOB_URL}/img/apple-touch-icon.png`}
        alternateLink={{
          url: `${ENV.PUBLIC_REMOTE_JOB_URL}${locale === 'en' ? '/en' : ''}`,
          lang: locale || 'vi'
        }}
      />
      <Header
        classCustom="fixed top-0 left-0"
        scrollDownClass=" border-b-[1px] border-[#e5e7eb]"
      />
      <div
        className={`bg-[url('/job-list/job_list_banner.mobile.png')] sm:bg-[url('/job-list/job_list_banner.png')] 2xl:bg-[url('/job-list/job_list_banner_big.png')]
        bg-[length:100%_auto] sm:bg-[length:1439px_400px] 2xl:bg-[length:1919px_400px] bg-[bottom_center] pt-[126px] sm:pt-[156px] pb-4 sm:pb-6`}>
        <div className="container m-auto px-4 sm:px-0 max-w-[1200px]">
          <h1 className="text-[40px] leading-[48px] sm:text-[56px] sm:leading-[68px] text-[#111827] font-[900] text-center mb-2">
            <Trans i18nKey="content:remote_job">
              <span className="text-primary" />
            </Trans>
          </h1>
          <h2 className="text-base sm:text-lg text-[#374151] mb-[54px] sm:mb-[38px] text-center max-w-[634px] m-auto">
            {t('content:num_job_of_month', {
              num_job: paging.meta?.count,
              month: dayjs().format('MM/YYYY')
            })}
          </h2>
          <JobSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            job_categories={job_categories}
            // paging={paging}
          />
        </div>
      </div>
      <div
        className="container m-auto px-4 sm:px-0 max-w-[1200px]"
        style={{ minHeight: 'calc(100vh - 516px)' }}>
        <div className="mb-[64px] ">
          <InfinityList
            {...paging}
            containerHeight={undefined}
            LoadedAllResultComponent={
              <div className="text-center text-[#6b7280] mt-8">
                You&apos;ve reached the end of the list
              </div>
            }
            NotFoundComponent={
              <div className="py-[100px] text-center">
                <Image
                  className="mb-6 m-auto"
                  width="128"
                  height="128"
                  src={NoResultImage}
                  alt="no result image"
                />

                <div className="text-lg text-[#111827] font-bold mb-1">
                  No results found
                </div>
                <div className="text-sm text-[#6b7280]">
                  We couldn&apos;t find what you searched for.
                  <br />
                  Try searching again
                </div>
              </div>
            }>
            <>
              <div className="font-medium text-[#374151] text-[18px] leading-6 py-6 sm:py-5">
                {searchValue?.job_category_id ||
                searchValue?.location_id ||
                searchValue?.title ? (
                  <span>
                    {paging.meta?.count} jobs for{' '}
                    <span className="text-[#6b7280] font-normal">
                      “
                      {[
                        ...(searchValue?.title
                          ? [searchValue?.title.toString()]
                          : []),
                        ...(searchValue?.location_id
                          ? [
                              locations.data.filter(
                                (location) =>
                                  location.id == searchValue?.location_id
                              )[0].attributes.state
                            ]
                          : []),
                        ...(searchValue?.job_category_id
                          ? [
                              job_categories
                                .map(
                                  (par_cate) =>
                                    par_cate.subs.filter((child_cate) => {
                                      return (
                                        String(child_cate.id) ==
                                        searchValue?.job_category_id
                                      )
                                    })[0]
                                )
                                .filter((cate) => cate)[0]?.name
                            ]
                          : [])
                      ].join(', ')}
                      ”
                    </span>
                  </span>
                ) : (
                  'Recent new opportunities'
                )}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
                {paging.data?.map((jobItem: JobIndexType) => (
                  <JobItem key={`job-item-${jobItem?.id}`} job={jobItem} />
                ))}
              </div>
            </>
          </InfinityList>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default JobList
