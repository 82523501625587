import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import client from '../core/api/client'
// import { FastJsonObject } from '../modules/job-list/job.types'
// import JobCategoriesFixture from './static/categories.json'

type JobCategoryType = {
  id: number
  name: string
  name_vn: string
  slug_en: string
  slug_vi: string
}

type JobCategoryParentResponseType = {
  attributes: JobCategoryType & {
    icon: string
    subs: { data: { attributes: JobCategoryType }[] }
  }
}

export type JobCategoryListType = {
  subs: (
    | {
        id: number
        name: string
        name_vn: string
        slug_en: string
        slug_vi: string
      }
    | {
        id: number
        name: string
        name_vn: string
        sub_label: string
      }
  )[]
  id: number
  name: string
  name_vn: string
  slug_en: string
  slug_vi: string
  icon: string
}[]

export const useJobCategories = () => {
  const [jobCategories, setJobCategories] = useState<JobCategoryListType>([])
  const fetchJobCategories = useCallback(
    () =>
      client.get<AxiosResponse<JobCategoryParentResponseType[]>>(
        '/api/v2/public/job_categories'
      ),
    []
  )

  useEffect(() => {
    if (jobCategories.length === 0)
      fetchJobCategories().then((res) => {
        const job_categories = res.data.data.map((ser) => ({
          ...ser.attributes,
          subs: [
            {
              id: ser.attributes.id,
              name: ser.attributes.name,
              name_vn: ser.attributes.name_vn,
              sub_label: 'All - '
            },
            ...ser.attributes.subs.data.map((ser2) => ({
              ...ser2.attributes
            }))
          ]
        }))

        const findIndexOthers = job_categories.findIndex(
          (session) => session.name.toLocaleLowerCase() === 'others'
        )
        if (findIndexOthers !== -1) {
          const filterRemoveOthers = job_categories.filter(
            (session) => session.name.toLocaleLowerCase() !== 'others'
          )
          filterRemoveOthers.push(job_categories[findIndexOthers])

          setJobCategories(filterRemoveOthers)
        }
      })
  }, [])

  return { job_categories: jobCategories }
}
