// import PartnerHome from '../modules/landing-page/partner-home/PartnerHome'

import React from 'react'
import { withCache } from '../core/utilities/ssr'
import JobList, { PAGE_SIZE_JOB_LIST } from '../modules/job-list'
import { getJobList } from './api/job'

export const getServerSideProps = withCache<
  React.ComponentProps<typeof JobList>
>(async () => {
  // Fetch data from external API
  const queryString = `employment_type[]=9&employment_type[]=6&items=${PAGE_SIZE_JOB_LIST}&page=1&`
  const res = await getJobList(queryString)
  // Pass data to the page via props
  return { props: { jobList: res.data } }
})

export default JobList
