import queryString from 'query-string'
import { removeEmptyProp } from './common'
export function parsePramsToQueryString(params: object): string {
  const final = removeEmptyProp(params)
  //@ts-ignore
  return queryString.stringify(final, {
    arrayFormat: 'bracket',
    encode: false
  })
}

function nomarlizeQueryName(key: string) {
  return key.match(/\[\]/) ? key.replace('[]', '') : key
}
export function nomarlizeQuery(query: any): any {
  return Object.keys(query).reduce(
    (final, key) => ({ ...final, [nomarlizeQueryName(key)]: query[key] }),
    {}
  )
}
