import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { Button } from '../../../components/Button'
import { Select } from '../../../core/components/forms'
import { Input } from '../../../core/components/FormControl/Input'
import { JobIndexType, JobSearchValue } from '../job.types'
import FormOptimize from '../../../core/components/FormControl/Form'
import { Controller } from 'react-hook-form'
import useLocation from '../../../hooks/useLocation'
import { JobCategoryListType } from '../../../hooks/useJobCategory'
import CloseFilledIcon from '../../../components/ui/icon/CloseFilledIcon'
import CheckedOutlineIcon from '../../../components/ui/icon/CheckedOutlineIcon'
import { ParsedUrlQuery } from 'querystring'

const JobSearch: FC<{
  searchValue?: JobSearchValue
  setSearchValue: (paritalQuery: ParsedUrlQuery) => void
  job_categories: JobCategoryListType
}> = ({ searchValue, setSearchValue, job_categories }) => {
  const locations = useLocation()

  const onFinish = (values: any) => {
    const data = {
      title: values?.title,
      location_id: values?.location_id?.value,
      job_category_id: values?.job_category_id?.value
    }

    setSearchValue && setSearchValue(data)
  }
  return (
    <>
      <style jsx global>{`
        .job-search__title {
          padding-left: 38px;
        }
        .job-search__location div[class$='singleValue'] .checked-icon,
        .job-search__category div[class$='singleValue'] .checked-icon {
          display: none;
        }
      `}</style>
      <FormOptimize
        onSubmit={onFinish}
        className="sm:bg-white sm:rounded-lg sm:p-4 sm:flex sm:justify-between"
        defaultValue={searchValue}
        renderSubmit={() => (
          <Button
            className="leading-5 hidden sm:block"
            htmlType="submit"
            size="large-xl">
            Search
          </Button>
        )}>
        {({ control, submit }: { control: any; submit: any }) => {
          return (
            <>
              <div className="relative sm:min-w-[42.63%] sm:max-w-[42.63%] mb-2 sm:mb-0">
                <Controller
                  control={control}
                  name="title"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      onChange={onChange}
                      value={value}
                      leftGroup={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.667 2.667a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm-5.333 4a5.333 5.333 0 1 1 9.546 3.27l3.592 3.592a.667.667 0 0 1-.943.942L9.937 10.88a5.333 5.333 0 0 1-8.604-4.213z"
                            fill="#9CA3AF"
                          />
                        </svg>
                      }
                      rightInput={
                        value && (
                          <Button
                            htmlType="button"
                            ghost
                            size="thin"
                            onClick={(e) => {
                              onChange('')
                            }}>
                            <CloseFilledIcon />
                          </Button>
                        )
                      }
                      className="job-search__title pr-10"
                      size="large-xl"
                      placeholder="Search for jobs"
                    />
                  )}
                />
              </div>
              <div className="relative sm:min-w-[20.55%] sm:max-w-[20.55%] mb-2 sm:mb-0">
                <Controller
                  control={control}
                  name="location_id"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="job-search__location"
                      name="location_id"
                      isClearable
                      onChange={(value) => {
                        onChange(value)
                        submit()
                      }}
                      placeholder="Location"
                      value={value}
                      options={
                        locations?.data
                          ? locations?.data.map((location) => ({
                              label: location.attributes.state,
                              value: String(location.attributes.id)
                            }))
                          : []
                      }
                    />
                  )}
                />
              </div>
              <div className="relative sm:min-w-[23.97%] sm:max-w-[23.97%]">
                <Controller
                  control={control}
                  name="job_category_id"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="job-search__category"
                      name="job_category_id"
                      isClearable
                      onChange={(value) => {
                        onChange(value)
                        submit()
                      }}
                      value={value}
                      placeholder="Categories"
                      options={
                        job_categories
                          ? job_categories.map((par_category) => ({
                              label: par_category.name,
                              options: par_category.subs
                                .filter((child) => par_category.id !== child.id)
                                .map((child_category) => ({
                                  label: child_category.name,
                                  value: String(child_category.id)
                                }))
                            }))
                          : []
                      }
                    />
                  )}
                />
              </div>
            </>
          )
        }}
      </FormOptimize>
    </>
  )
}

export default JobSearch
