import { CSSProperties, FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

const FormOptimize: FC<{
  children?: any
  onSubmit?: (data: object) => void
  renderSubmit?: () => void
  defaultValue?: object
  noUseSubmit?: boolean
  className?: string
  customStyle?: CSSProperties
  id?: string
}> = (props) => {
  const {
    children,
    onSubmit,
    renderSubmit,
    defaultValue,
    noUseSubmit,
    className = '',
    customStyle,
    id
  } = props
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors }
  } = useForm({ defaultValues: defaultValue })

  const handleOnSubmit: SubmitHandler<object> = (data) => {
    if (onSubmit) {
      onSubmit(data)
    }
  }
  const submitFuction = handleSubmit(handleOnSubmit)

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form
      action="form"
      target="_top"
      className={className}
      style={customStyle}
      onSubmit={submitFuction}
      id={id}>
      {children({
        register,
        errors,
        setValue,
        control,
        reset,
        submit: submitFuction,
        getValues
      })}
      {noUseSubmit ? (
        <input hidden={!!noUseSubmit} type="submit" />
      ) : renderSubmit ? (
        renderSubmit()
      ) : (
        <input type="submit" />
      )}
    </form>
  )
}

export default FormOptimize
