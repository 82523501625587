import {
  FC,
  LegacyRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { FastJsonList } from '../../types/RailsResponseType'
type MetaType = {
  count?: number
  last?: number
  next?: number
  page?: number
  prev?: number
  prev_url?: string
  next_url?: string
}

interface AxiosResponse<T = any> {
  data: T
  status: number
  statusText: string
  headers: any
  config: any
  request?: any
}

type InfinitiScrollMaterial<T> = {
  data?: T[]
  fetchNextPage: () => Promise<any>
  reload: () => Promise<any>
  // reloadPartialPage?: (index: number) => Promise<any>
  fetchingMore: boolean
  haveNext: boolean
  meta?: MetaType | undefined
}

// const PAGE_ITEMS = 10
export function useInfinityScroll<T>(
  initData: FastJsonList<T>,
  fetchNextPage: (page: number) => Promise<AxiosResponse<FastJsonList<T>>>
): InfinitiScrollMaterial<T> {
  // const perpage = PAGE_ITEMS
  const [data, setData] = useState<FastJsonList<T>>(initData)
  const [page, setPage] = useState<number>(1)
  const [fetchingMore, setFetchingMore] = useState(false)

  return {
    data: data?.data?.map((item) => item.attributes),
    reload: () => {
      return fetchNextPage(1).then((response) => {
        setData(response.data)
        if (response?.data?.meta?.page) setPage(response?.data?.meta?.page)
        return response
      })
    },
    fetchNextPage: () => {
      setFetchingMore(true)
      return fetchNextPage(page + 1)
        .then((response) => {
          // transform data
          // const responseValue = transformData(response)
          setData({
            ...response.data,
            data: [
              ...(data?.data || []),
              ...(response.data.data ? response.data.data : [])
            ]
          })
          setPage(page + 1)
          return response
        })
        .finally(() => {
          setFetchingMore(false)
        })
    },
    // reloadPartialPage: (index: number) => {
    //   const fetchPage = Math.floor(index / perpage) + 1
    //   return fetchNextPage(fetchPage).then((response) => {
    //     const startIndex = (fetchPage - 1) * perpage
    //     const newList = [...(data?.data ? data.data : [])]
    //     newList.splice(
    //       startIndex,
    //       perpage,
    //       ...(response.data.data ? response.data.data : [])
    //     )
    //     setDecoreData({ ...decoreData, data: newList })
    //     return response
    //   })
    // },
    fetchingMore,
    haveNext: data ? !!data?.meta?.next : false,
    meta: data && data?.meta
  }
}

export const InfinityList: FC<{
  fetchNextPage: () => Promise<any>
  fetchingMore: boolean
  haveNext: boolean
  meta?: MetaType
  loading?: boolean
  data?: Array<any>
  containerHeight?: number
  NotFoundComponent?: ReactElement
  children?: any
  LoadedAllResultComponent?: ReactElement
}> = ({
  children,
  fetchNextPage,
  fetchingMore,
  haveNext,
  data,
  loading,
  containerHeight,
  NotFoundComponent,
  LoadedAllResultComponent
}) => {
  const dataViewContainerRef = useRef<HTMLDivElement>()
  const functionRef = useRef<{
    fetching: boolean
    fetchNext?: () => Promise<any>
    haveNext?: boolean
  }>({ fetching: false })
  functionRef.current.fetchNext = () => fetchNextPage()
  functionRef.current.haveNext = haveNext
  const scrollTrackingHandler = useCallback(() => {
    if (
      dataViewContainerRef.current &&
      dataViewContainerRef.current.getBoundingClientRect().bottom <=
        (containerHeight || window.innerHeight)
    ) {
      if (!functionRef.current.fetching && functionRef.current.haveNext) {
        functionRef.current.fetching = true
        functionRef?.current?.fetchNext &&
          functionRef?.current?.fetchNext().then(() => {
            functionRef.current.fetching = false
          })
      }
    }
  }, [containerHeight])
  useEffect(() => {
    if (containerHeight !== undefined) {
      const currentDataView = dataViewContainerRef.current
      dataViewContainerRef.current?.addEventListener(
        'scroll',
        scrollTrackingHandler
      )
      return () => {
        currentDataView?.removeEventListener('scroll', scrollTrackingHandler)
      }
    } else {
      window.addEventListener('scroll', scrollTrackingHandler)
      return () => {
        window.removeEventListener('scroll', scrollTrackingHandler)
      }
    }
  }, [dataViewContainerRef, containerHeight, scrollTrackingHandler])
  return loading === true ? (
    <>loading...</>
  ) : data && data.length === 0 ? (
    NotFoundComponent ? (
      NotFoundComponent
    ) : (
      <></>
    )
  ) : (
    <div
      style={
        containerHeight ? { height: containerHeight, overflow: 'scroll' } : {}
      }
      ref={dataViewContainerRef as LegacyRef<HTMLDivElement>}>
      {children}
      {fetchingMore ? (
        <div className="text-center">
          <div className="lds-ring">
            <style jsx>{`
              .lds-ring {
                display: inline-block;
                position: relative;
                bottom: -46px;
                width: 24px;
                height: 24px;
              }
              .lds-ring div {
                box-sizing: border-box;
                display: block;
                position: absolute;
                width: 23px;
                height: 23px;
                margin: 0;
                border: 2px solid #1890ff;
                border-radius: 50%;
                animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border-color: #1890ff transparent transparent transparent;
              }
              .lds-ring div:nth-child(1) {
                animation-delay: -0.45s;
              }
              .lds-ring div:nth-child(2) {
                animation-delay: -0.3s;
              }
              .lds-ring div:nth-child(3) {
                animation-delay: -0.15s;
              }
              @keyframes lds-ring {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            `}</style>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        LoadedAllResultComponent
      )}
    </div>
  )
}
