import axios, { AxiosInstance } from 'axios'
import ENV from '../../env'
const AUTHENTICATION_STORAGE_KEY = 'X_TOKEN'
export type APIClientType = AxiosInstance
const client = axios.create({
  baseURL: ENV.PUBLIC_API_BASE_URL
})
client.interceptors.request.use(function (config) {
  return {
    ...config,
    headers: {
      Authentication: `${localStorage.getItem(AUTHENTICATION_STORAGE_KEY)}`
    }
  }
})

export const setGlobalAuthenticationRequestHeader = (value: string) => {
  localStorage.setItem(AUTHENTICATION_STORAGE_KEY, value)
}
export const useApiClient = () => {
  // handle global api http status here
  return client
}

export default client
