import { FC } from 'react'
const CloseFilledIcon: FC<{ color?: string; size?: number }> = ({
  color = '#9CA3AF',
  size = 16
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.4A6.4 6.4 0 1 0 8 1.6a6.4 6.4 0 0 0 0 12.8zM6.965 5.834a.8.8 0 1 0-1.131 1.132L6.868 8 5.834 9.034a.8.8 0 0 0 1.131 1.132L8 9.13l1.034 1.035a.8.8 0 0 0 1.131-1.132L9.131 8l1.034-1.034a.8.8 0 0 0-1.131-1.132L8 6.87 6.965 5.834z"
        fill={color}
      />
    </svg>
  )
}

export default CloseFilledIcon
